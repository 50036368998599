<template>
  <div class="publish-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#F6F9FC' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon class="icon" :icon="['fad', 'info']" />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{ $t('knowledge.staged-version.modals.publish.title') }}
                </p>
              </div>
              <el-divider class="my-3 w-divider"></el-divider>
              <p>
                {{ $t('knowledge.staged-version.modals.publish.description') }}
              </p>
              <p>
                {{ $t('knowledge.staged-version.modals.publish.warning') }}
                <span v-if="languages.length">:</span>
                <span v-for="lang in languages" :key="lang">
                  {{ $t(`knowledge.languages.${lang}`) }},
                </span>
              </p>
            </div>
          </modal-header>
        </div>
      </div>
      <div slot="footer" class="col-12 d-flex justify-content-around">
        <el-button
          class="ml-2"
          type="secondary"
          size="sm"
          @click="userChoice(false)"
          >{{ $t('knowledge.staged-version.modals.publish.cancel') }}</el-button
        >
        <el-button
          class="button-publish"
          type="primary"
          size="sm"
          :disabled="fakeLoading"
          @click="userChoice(true)"
        >
          <font-awesome-icon
            v-if="fakeLoading"
            :icon="['far', 'spinner']"
            spin
          />
          <span v-else>{{
            $t('knowledge.staged-version.modals.publish.publish')
          }}</span>
        </el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';

export default {
  name: 'publish-staged-version-modal',
  props: {
    display: Boolean,
    languages: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Modal,
    ModalHeader,
  },
  data() {
    return {
      localDisplay: this.display,
      fakeLoading: false,
    };
  },
  methods: {
    userChoice(choice) {
      if (choice) {
        // fake delay to simulate wait of request response to avoid visual tilt
        this.fakeLoading = true;

        this.$emit('events', {
          eventName: 'choice',
          eventData: true,
          eventWait: 1000,
        });
      } else this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.publish-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.icon {
  width: 26px;
  height: 26px;
}

.w-divider {
  background-color: white;
}

.button-publish {
  margin-right: 8px;
  width: 100px;
}
</style>
